import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import './AccountServicesHistory.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import { selectors, operations } from 'ducks/accountService';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';


class AccountServicesHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleViewHistory = (event) => {
    const { getHistoryItems } = this.props;
    getHistoryItems();
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }


  handleViewHistoryItem = (item) => {
    const { getCapModHistory } = this.props;
    getCapModHistory(item);
  }

  getCSTDateTime = dateTime => (R.isNil(dateTime) ? 'N/A' : moment.utc(dateTime).tz('America/Chicago').format('MM/DD/YYYY'))

  renderDropDownItems = () => {
    const { historyData } = this.props;
    return (!R.isEmpty(historyData) ? historyData.map((item, index) => (
      <>
        <div styleName="display-flex">
          <div>
            <h3 style={{ margin: 0 }}>{this.getCSTDateTime(item.completedDate)}</h3>
            {<h5 styleName="capMod">{item.capModId ? item.capModId : '--'}</h5>}
          </div>
          <Icon
            color="primary"

            onClick={() => this.handleViewHistoryItem(item.capModId)}
            styleName="view"
          >
visibility

          </Icon>
        </div>
        {index + 1 !== historyData.length && <Divider styleName="divider" />}
      </>
    ))
      : (
        <div styleName="display-flex">
        No historical checklists are available
        </div>
      ));
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip placement="left" title="CapMod History">
          <Icon
            onClick={this.handleViewHistory}
            style={{ cursor: 'pointer' }}
          >
            history
          </Icon>
        </Tooltip>

        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {this.renderDropDownItems()}
        </Popover>
      </div>
    );
  }
}

AccountServicesHistory.defaultProps = {
  historyData: null,
};


AccountServicesHistory.propTypes = {
  getCapModHistory: PropTypes.func.isRequired,
  getHistoryItems: PropTypes.func.isRequired,
  historyData: PropTypes.arrayOf(),
};

const mapStateToProps = state => ({
  historyData: selectors.getHistoryData(state),
});

const mapDispatchToProps = dispatch => ({
  getHistoryItems: operations.storeAccountServicesHistoryOperation(dispatch),
  getCapModHistory: operations.getCapModHistoryOperation(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(AccountServicesHistory);
