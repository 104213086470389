import { makeStyles } from '@material-ui/core/styles';

const InformationStyles = makeStyles(() => ({
  caseFields: {
    alignItems: 'center',
    height: '45px',
  },
  caseLabel: {
    marginLeft: '20px',
    fontSize: '0.875rem',
  },
  caseField: {
    display: 'flex',
  },
  checkBoxRoot: {
    paddingLeft: '0px',
    paddingTop: '3px',
  },
  checkBoxGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const InputTextFieldStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  labelName: {
    paddingTop: '8px',
    transform: 'none',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginLeft: '20px',
    color: '#4E586E',
    display: 'flex',
  },
  TextFieldDiv: {
    // marginLeft: '335px',
    '& .MuiInputBase-root': {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#4E586E',
      border: hookData => (hookData.override && hookData.showDetails ? 'none' : '1px solid #E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
  },
  Currency: {
    marginRight: hookData => (hookData.override && hookData.showDetails ? '0px' : '8px'),
    marginLeft: hookData => (hookData.override && hookData.showDetails ? '0px' : '8px'),
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '400',
      color: hookData => (hookData.override && hookData.showDetails ? '#4E586E' : '#939299'),
    },
  },
  DetailsButton: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#596FEB',
    paddingBottom: '0px',
    marginLeft: '9px',
    paddingTop: '0px',
  },
}));

export default {
  InformationStyles,
  InputTextFieldStyles,
};
