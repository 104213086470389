/* eslint-disable import/no-named-default */
/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Button, Tabs, Tab, Grid, Dialog, DialogContent, DialogContentText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ContentHeader from 'components/ContentHeader';
import PopupContainer from 'components/PopupContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { operations as dashboardOperations } from 'ducks/dashboard';
import { operations, selectors } from 'ducks/admin-tools';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {
  UN_BOOK, UN_REJECT, UN_FAIL, CREATE_STATE_EVAL_CASE, UPDATE_INTEREST_RATE,
  MOD_SUPPORT, UPLOAD_DATA, TEMPLATE_FILE_INFO,
  DEFAULT_ROWS_PER_PAGE,
} from '../../constants/busniessAdmin';
import FilterSection from './FilterSection';
import { default as styles } from './BusinessAdmin.css';
import ProductBusiness from '../../components/ProductBusiness';
import CreateStateEvalCase from './ModSupport/CreateStateEvalCase/CreateStateEvalCase';
import UnFail from './ModSupport/UnFail/UnFail';
import UnReject from './ModSupport/UnReject/UnReject';
import Dropzone from './DropZone';
import UnBook from './ModSupport/UnBook/UnBook';
import UpdateInterestRate from './ModSupport/UpdateInterestRate/UpdateInterestRate';
import History from './ModSupport/History/History';
import UploadDataHistory from './UploadData/History/UploadDataHistory';

// eslint-disable-next-line react/prop-types
const TabContent = ({ value = 0, index = 0, children }) => (
  <div
    hidden={value !== index}
    index={index}
    role="tabpanel"
    style={{ overflow: 'auto', alignSelf: 'stretch' }}
    value={value}
  >
    {children}
  </div>
);

class BusinessAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderLeftPane = this.renderLeftPane.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderSelectedTab = this.renderSelectedTab.bind(this);
    this.isActive = this.isActive.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleUploadedFiles = this.handleUploadedFiles.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.state = {
      index: 0,
      currentAction: '',
      activeTab: 'UnBook',
      show: false,
      title: 'BUSINESS ADMIN',
      modalOpen: false,
      sheetErrorMsg: '',
    };
  }

  componentDidMount() {
    const { setBASelectedTab } = this.props;
    setBASelectedTab(UN_BOOK);
  }

  handleDownload = () => {
    const { downloadFile } = this.props;
    const { activeTab } = this.state;
    const { fileName, columnHeaders } = TEMPLATE_FILE_INFO[activeTab];
    const data = [];
    const payload = {
      fileName,
      columnHeaders,
      data,
    };
    downloadFile(payload);
  }

  handleClose = () => {
    const { show } = this.state;
    this.setState({
      show: !show,
    });
  };

  handleUploadedFiles = (uploadedFilesJson) => {
    const { activeTab } = this.state;
    const { submitUploadSheetData } = this.props;
    const { columnHeaders } = TEMPLATE_FILE_INFO[activeTab];
    let isUploadedFilesInvalid = null;
    let hasRequiredColumns = false;
    let validateSheetData = true;
    const SheetcolumnHeaders = columnHeaders[0];
    uploadedFilesJson.forEach((item) => {
      const uploadedFileValues = Object.values(item);
      const hasAtleastOneRow = uploadedFileValues.length > 0 && (uploadedFileValues[0].length > 0);
      hasRequiredColumns = hasAtleastOneRow
        && ((SheetcolumnHeaders.length === uploadedFileValues[0][0].length)
          && SheetcolumnHeaders.every(
            (value, index) => value === uploadedFileValues[0][0][index],
          )
        );
      if (activeTab !== 'SMDU Manual') {
        validateSheetData = uploadedFileValues[0].length > 1 && uploadedFileValues.every(
          val => val.every(feild => feild.length === SheetcolumnHeaders.length)
        && val.every(dd => dd.every(x => x !== null)),
        );
      }
      if (activeTab === 'SMDU Manual') {
        validateSheetData = uploadedFileValues[0].length > 1 && uploadedFileValues.every(
          val => val.every(feild => feild.length >= 1),
        );
      }

      if (!(hasAtleastOneRow && hasRequiredColumns)) {
        isUploadedFilesInvalid = true;
        this.setState({
          sheetErrorMsg: 'The uploaded template is not in the expected format. Please upload the template in the expected format and try again.',
        });
      } else if (!validateSheetData) {
        isUploadedFilesInvalid = true;
        this.setState({
          sheetErrorMsg: 'All column data is required and this file cannot be processed. Please add missing data and try again.',
        });
      }
    });
    if (!isUploadedFilesInvalid) {
      submitUploadSheetData(activeTab, uploadedFilesJson);
    }
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      sheetErrorMsg: '',
    });
  }

  applyFilter(values) {
    const { applyBAFilter } = this.props;
    // eslint-disable-next-line no-unused-vars
    applyBAFilter(Object.assign({}, Object.fromEntries(Object.entries(values).filter(([_key, v]) => v !== '')),
      { busKeyType: 'EMPTY' }),
    0, DEFAULT_ROWS_PER_PAGE);
  }

  handleChange(_, idx) {
    const { index } = this.state;
    const { setBASelectedTab } = this.props;
    if (index !== idx) {
      this.setState({
        index: idx,
        activeTab: (idx === 0 ? 'UnBook' : 'All'),
      });
      setBASelectedTab(idx === 0 ? UN_BOOK : 'All');
    }
  }

  handleActionChange(selectedTab) {
    const { setBASelectedTab } = this.props;
    this.setState({
      activeTab: selectedTab,
    });
    setBASelectedTab(selectedTab);
  }

  isActive(modAction) {
    const { currentAction } = this.state;
    return modAction === currentAction;
  }

  renderSelectedTab() {
    const { activeTab } = this.state;
    switch (activeTab) {
      case UN_BOOK:
        return (<UnBook />);
      case UN_REJECT:
        return (<UnReject />);
      case UN_FAIL:
        return (<UnFail />);
      case CREATE_STATE_EVAL_CASE:
        return (
          <CreateStateEvalCase />
        );
      case UPDATE_INTEREST_RATE:
        return (
          <UpdateInterestRate />
        );
      default:
        return (<div>Component Unavailable</div>);
    }
  }

  renderLeftPane() {
    const { index, activeTab } = this.state;
    return (
      <div>
        <section styleName="leftPaneContainer">
          <Tabs
            indicatorColor="primary"
            onChange={this.handleChange}
            style={{ width: '100%', borderBottom: 'solid 1px #E0E4EF' }}
            textColor="#4e586e"
            value={index}
            variant="fullWidth"
          >
            <Tab
              label="MOD SUPPORT"
              style={{ minWidth: '52%', overflow: 'hidden !important' }}
            />
            <Tab
              label="UPLOAD DATA"
              style={{ minWidth: '49%', overflow: 'hidden !important' }}
            />
          </Tabs>
          <TabContent index={0} value={index}>
            <ProductBusiness
              actionHeader={MOD_SUPPORT}
              activeTab={activeTab}
              handleChange={this.handleActionChange}
            />
          </TabContent>
          <TabContent index={1} value={index}>
            <ProductBusiness
              actionHeader={UPLOAD_DATA}
              activeTab={activeTab}
              handleChange={this.handleActionChange}
            />
          </TabContent>
        </section>
      </div>
    );
  }

  render() {
    const {
      activeTab, show, title, index,
      modalOpen, sheetErrorMsg,
    } = this.state;
    const {
      fetchModHistoryData, isFilterEnabled, fetchMatchedBAData, users,
      fetchUploadDataHistory, businessAdminData,
    } = this.props;
    return (
      <>
        <ContentHeader title={title} />
        <div className={styles.businessAdminContainer}>
          <div className={styles.prodSection}>
            {this.renderLeftPane()}
          </div>

          <div
            className={index === 1 ? `${styles.historySection}`
              : `${styles.historySection} ${styles.nonHistory}`}
          >
            {index === 1 ? null : (
              <div className={styles.formContainer}>
                {this.renderSelectedTab()}
              </div>
            )}
            <div className={styles.tableContainer}>
              {index === 1 && (
              <Grid alignItems="center" container justify="space-between" spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify="space-between" spacing={0} styleName="header-container">
                    <Grid item>
                      <h3>
                        Upload History
                      </h3>
                    </Grid>
                    { isFilterEnabled && (
                    <h3 style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '1rem' }}>
                      {businessAdminData && businessAdminData.totalRecords
                        ? businessAdminData.totalRecords : 0 }
                      {' '}
                  Records
                      <img alt="filter icon" src="/static/img/filter.png" style={{ height: '1rem', marginRight: '1rem' }} />
                    </h3>
                    )
  }
                    {activeTab !== 'All' && !isFilterEnabled && (
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={this.handleDownload}
                          size="large"
                          startIcon={<GetAppIcon />}
                          type="button"
                        >
                        Download Template
                        </Button>
                        <Button color="primary" onClick={this.handleClose} size="large" type="button" variant="contained">
                        UPLOAD
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <UploadDataHistory activeTab={activeTab} />
                </Grid>
                <Grid item styleName="headline-btn-container">
                  <>
                    <Dialog
                      aria-describedby="alert-dialog-description"
                      aria-labelledby="alert-dialog-title"
                      fullWidth
                      maxWidth="xs"
                      open={modalOpen}
                    >
                      <IconButton
                        onClick={this.handleModalClose}
                        styleName="closeIcon"
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent styleName="content">
                        {!sheetErrorMsg
                          ? (
                            <>
                              <IconButton
                                styleName="doneIcon"
                              >
                                <CheckCircleOutlineIcon />
                              </IconButton>
                              <DialogContentText styleName="content">
                                {'Document Uploaded Successfully'}
                              </DialogContentText>
                              <Button
                                autoFocus
                                color="primary"
                                onClick={this.handleModalClose}
                                styleName="okButton"
                                type="button"
                                variant="contained"
                              >
                              OK
                              </Button>
                            </>
                          )
                          : (
                            <>
                              <DialogContentText styleName="content-error">
                                {sheetErrorMsg}
                              </DialogContentText>
                            </>
                          )}
                      </DialogContent>
                    </Dialog>
                  </>
                </Grid>
              </Grid>
              )}
              <PopupContainer
                closeIcon={false}
                handleClose={this.handleClose}
                show={show}
                title="Upload Documents"
              >
                <Dropzone
                  handleClose={this.handleClose}
                  handleUploadedFiles={this.handleUploadedFiles}
                />
              </PopupContainer>
              { index === 0 && (
              <>
                <Grid container direction="row">
                  <Grid item xs={8}>
                    <h3>History</h3>
                  </Grid>
                  <Grid item xs={4}>
                    { isFilterEnabled && (
                    <h3 style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '1rem' }}>
                      {businessAdminData && businessAdminData.totalRecords
                        ? businessAdminData.totalRecords : 0 }
                      {' '}
                  Records
                      <img alt="filter icon" src="/static/img/filter.png" style={{ height: '1rem', marginRight: '1rem' }} />
                    </h3>
                    )
  }
                  </Grid>
                </Grid>
                <History activeTab={activeTab} />
              </>
              )
            }
            </div>
          </div>
          <div
            className={styles.filterSection}
          >
            <FilterSection
              activeTab={activeTab}
              fetchMatchedData={fetchMatchedBAData}
              onReset={(inUploadDataTab) => {
                const getData = inUploadDataTab ? fetchUploadDataHistory : fetchModHistoryData;
                if (isFilterEnabled) {
                  getData(0, DEFAULT_ROWS_PER_PAGE);
                }
              }}
              submitFilterOptions={this.applyFilter}
              users={users}
            />
          </div>
        </div>
      </>
    );
  }
}


BusinessAdmin.defaultProps = {
  setBASelectedTab: () => { },
  fetchModHistoryData: () => { },
  applyBAFilter: () => { },
  isFilterEnabled: false,
  fetchMatchedBAData: () => { },
  fetchUploadDataHistory: () => { },
  submitUploadSheetData: () => { },
};

BusinessAdmin.propTypes = {
  applyBAFilter: PropTypes.func,
  businessAdminData: PropTypes.shape().isRequired,
  downloadFile: PropTypes.func.isRequired,
  fetchMatchedBAData: PropTypes.func,
  fetchModHistoryData: PropTypes.func,
  fetchUploadDataHistory: PropTypes.func,
  isFilterEnabled: PropTypes.bool,
  setBASelectedTab: PropTypes.func,
  submitUploadSheetData: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  isFilterEnabled: selectors.isFilterEnabled(state),
  users: selectors.getUsers(state),
  businessAdminData: selectors.getBusinessAdminGridData(state),
});

const mapDispatchToProps = dispatch => ({
  setBASelectedTab: operations.setBASelectedTab(dispatch),
  fetchModHistoryData: operations.fetchBusinessAdminHistoryData(dispatch),
  fetchUploadDataHistory: operations.fetchBusinessAdminUploadDataHistory(dispatch),
  applyBAFilter: operations.applyBAFilter(dispatch),
  fetchMatchedBAData: operations.fetchMatchedBAData(dispatch),
  downloadFile: dashboardOperations.downloadFile(dispatch),
  submitUploadSheetData: operations.submitUploadSheetData(dispatch),
});

const BusinessAdminContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessAdmin);

export default withRouter(BusinessAdminContainer);
