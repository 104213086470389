/* eslint no-param-reassign: "error" */
import {
  SUBMIT_TO_FNMA, SET_SMDU_RESPONSE_DATA, SET_SMDU_HISTORY_DATA, SET_SMDU_HISTORY_DARTS_DATA,
  // SET_SMDU_HISTORY_DARTS_DATA,
  SET_TRANSACTION_DATA, LOADING_SMDU_WIDGET,
  HANDLE_SMDU_FAILURE, SET_SMDU_CASE_MANAGEMENT,
  SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN, SET_TO_REPORT_INVESTOR, STOP_LOADING_SMDU_WIDGET,
  CLOSE_POP_UP, HANDLE_SMDU_SUCCESS, SET_PRIOR_WORKOUT_HISTORY, HANDLE_ADD_PRIOR_WORKOUT_SUCCESS,
  SET_SMDU_REPORTING_UPDATES, SET_RESL_CHOICE_TYPE,
} from './types';

const initialState = {
  fnmaRequest: '',
  smduResponseData: {},
  smduHistoryData: {},
  smduTransactionData: {},
  smduHistoryDartsData: {},
  smduCaseManagementData: {},
  reportToInvestor: '',
  loading: true,
  popUpFlag: false,
  isPopUpEnabled: false,
  popUpMessage: '',
  popUpType: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SMDU_WIDGET: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUBMIT_TO_FNMA: {
      return {
        ...state,
        fnmaRequest: action.payload,
      };
    }
    case SET_SMDU_RESPONSE_DATA: {
      return {
        ...state,
        smduResponseData: action.payload,
        loading: false,
      };
    }
    case HANDLE_SMDU_SUCCESS: {
      const popMsg = action.payload;
      return {
        ...state,
        popUpMessage: popMsg || 'Success',
        popUpType: 'Success',
        isPopUpEnabled: true,
      };
    }
    case HANDLE_SMDU_FAILURE: {
      const popMsg = action.payload;
      return {
        ...state,
        isPopUpEnabled: true,
        errorFlag: true,
        popUpMessage: popMsg || 'SOMETHING WENT WRONG',
        popUpType: 'Failed',
      };
    }
    case HANDLE_ADD_PRIOR_WORKOUT_SUCCESS: {
      return {
        ...state,
        prioWorkoutPopupMessage: action.payload || 'Success',
        popUpType: 'Success',
        isPopUpEnabled: true,
      };
    }
    case CLOSE_POP_UP: {
      return {
        ...state,
        isPopUpEnabled: false,
        popUpMessage: '',
        popUpType: '',
      };
    }
    case SET_SMDU_HISTORY_DATA: {
      return {
        ...state,
        smduHistoryData: action.payload,
        loading: false,
      };
    }
    case SET_TRANSACTION_DATA: {
      return {
        ...state,
        smduTransactionData: action.payload,
      };
    }
    case SET_SMDU_HISTORY_DARTS_DATA: {
      return {
        ...state,
        smduHistoryDartsData: action.payload,
      };
    }
    case SET_SMDU_CASE_MANAGEMENT: {
      return {
        ...state,
        smduCaseManagementData: action.payload,
      };
    }

    case SET_SMDU_REPORTING_UPDATES: {
      return {
        ...state,
        smduReportingUpdates: action.payload,
      };
    }

    case SAVE_SMDU_PRIOR_WORKOUT_DROPDOWN: {
      return {
        ...state,
        SMDUPriorWorkoutDropdownOptions: action.payload,
      };
    }
    case SET_TO_REPORT_INVESTOR: {
      return {
        ...state,
        reportToInvestor: action.payload,
        loading: false,
      };
    }
    case STOP_LOADING_SMDU_WIDGET: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_PRIOR_WORKOUT_HISTORY: {
      return {
        ...state,
        smduPriorWorkoutHistory: action.payload,
      };
    }

    case SET_RESL_CHOICE_TYPE: {
      return {
        ...state,
        resolutionType: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
