import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core/';
import RateInputBoxStyles from './RateInputBoxStyles';

const RateInput = ({
  id, label, onChange, override, value, type, columnType,
}) => {
  const [rateValue, setValue] = useState(value);
  const classes = RateInputBoxStyles({ override });

  const isValidInput = (val) => {
    let inputValue = val;
    if (inputValue.length > 0) {
      inputValue = inputValue.trim();
      inputValue = inputValue.replaceAll(' ', '');
      inputValue = inputValue.replaceAll('%', '');
    }
    return Number((inputValue) >= 0);
  };

  const handleChange = (event) => {
    let inputValue = event.target.value.trim().replaceAll(' ', '').replaceAll('%%', '%');
    inputValue = inputValue !== '%' && inputValue.startsWith('%') ? inputValue.substring(1, inputValue.lastIndexOf('%') > 0
      ? inputValue.lastIndexOf('%') + 1 : inputValue.length)
      : inputValue;
    if ((inputValue.endsWith('%') && Number.isNaN(Number(inputValue.substring(0, inputValue.lastIndexOf('%')))))) {
      return;
    }
    if (isValidInput(inputValue)) {
      setValue(inputValue);
      onChange(label, inputValue, columnType);
    }
  };

  const handleBlur = () => {
    if (rateValue) {
      const roundedValue = parseFloat(rateValue).toFixed(3);
      setValue(roundedValue);
      onChange(label, roundedValue, columnType);
    }
  };

  return (
    <FormControl className={classes.root}>
      <TextField
        className={classes.TextFieldDiv}
        disabled={override}
        id={id}
        InputProps={{
          // inputProps: { min: 0, inputMode: 'numeric' },
          disableUnderline: true,
          style: { paddingLeft: '5px' },
          // startAdornment:
          // <InputAdornment className={classes.Currency} position="start">%</InputAdornment>,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={rateValue}
      />
    </FormControl>
  );
};

RateInput.propTypes = {
  columnType: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  override: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

RateInput.defaultProps = {
  label: '',
  onChange: () => { },
  override: true,
  value: '',
  type: 'text',
  columnType: '',
};

export default RateInput;
