import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, ThemeProvider, Button, Dialog, DialogContent, DialogActions, Divider,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useStyles } from './tabPanelStyle';
import FileUploadDialog from '../components/Uploadfile/UploadFile';
import DownloadExcel from '../components/DownloadExcel/DownloadExcel';
import RecordValidation from '../Onboard/RecordValidation';

const TabPanel = ({
  tabNames, selectedTab, onTabSelect, isOnboardEnabled, isUploadCompleted, handleUploadState,
  setIsSubmitDisabled, fileUploadData, inProgress,
  initiateImportProcessOperation, getExceptionData, getProcesseFiledData, onboardData,
  submitBtn, setSubmitBtnOperation,
}) => {
  const classes = useStyles();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSubmitValidBtnDisabled, setIsSubmitValidBtnDisabled] = useState(false);
  // const [isUploadCompleted, setisUploadCompleted] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const handleDownloadButtonClick = () => {
    setIsSubmitDisabled(true);
    setIsButtonEnabled(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const getValidRecords = () => {
    const validLoanIds = getExceptionData.filter(
      record => !record['Critical Severity Exceptions'] || record['Critical Severity Exceptions'].length === 0,
    ).map(record => record['Loan ID'].toString());
    const recordsWithoutCriticalException = getProcesseFiledData && getProcesseFiledData.uploadRequest && getProcesseFiledData.uploadRequest.filter(record => validLoanIds.includes(record['Loan ID'].toString()));
    return recordsWithoutCriticalException;
  };

  useEffect(() => {
    setIsButtonEnabled(isOnboardEnabled && selectedTab === 0 && isUploadCompleted);
  }, [isOnboardEnabled, selectedTab, isUploadCompleted]);

  useEffect(() => {
    setIsSubmitValidBtnDisabled(isOnboardEnabled && selectedTab === 0 && isUploadCompleted
      && getExceptionData.length !== 0 && getValidRecords().length !== 0 && submitBtn);
  }, [isOnboardEnabled, selectedTab, isUploadCompleted, getExceptionData, submitBtn]);

  const handleUploadComplete = () => {
    handleUploadState(true);
  };

  const handleSuccessDialogOpen = () => {
    initiateImportProcessOperation(getValidRecords());
    setSuccessDialogOpen(true);
  };

  const handleNavigateToHistory = () => {
    setSuccessDialogOpen(false);
    onTabSelect(1);
    setSubmitBtnOperation(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <ThemeProvider>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tabs
          className={classes.tab}
          data-test="tab-panel-test"
          indicatorColor="primary"
          onChange={onTabSelect}
          value={selectedTab}
        >
          {tabNames.map((tab, index) => (
            <Tab
              key={tab.id || index}
              className={classes.textName}
              index={tab.id || 0}
              label={<div>{tab}</div>}
            />
          ))}
        </Tabs>
        {!inProgress && selectedTab === 0 && isOnboardEnabled && isUploadCompleted
            && getExceptionData.length !== 0 && (
            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <RecordValidation getExceptionData={getExceptionData} />
            </div>
        )}
        {selectedTab === 0 && isOnboardEnabled && !isUploadCompleted && (
          <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
            <DownloadExcel
              fileUploadData={onboardData}
              isUploadCompleted={isUploadCompleted}
              onDownloadComplete={handleDownloadButtonClick}
            />
          </div>
        )}
        {selectedTab === 0 && isOnboardEnabled && !isUploadCompleted && (
          <div>
            <Button
              color="primary"
              disabled={!isButtonEnabled}
              id="uploadButton"
              onClick={() => setDialogOpen(true)}
              variant="contained"
            >
              UPLOAD
            </Button>
          </div>
        )}
        {selectedTab === 0 && isOnboardEnabled && isUploadCompleted && (
          <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
            <DownloadExcel
              fileUploadData={fileUploadData}
              isUploadCompleted={isUploadCompleted}
              onDownloadComplete={handleDownloadButtonClick}
            />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {selectedTab === 0 && isOnboardEnabled && isUploadCompleted && (
            <div style={{ marginRight: '8px' }}>
              <Button
                disabled={fileUploadData.length === 0 || !isButtonEnabled}
                id="uploadButton"
                onClick={() => setDialogOpen(true)}
                style={{
                  ...(fileUploadData.length > 0 && isButtonEnabled
                    ? {
                      color: '#596FEB',
                      border: '1px solid #596FEB',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                    : {}),
                }}
                variant="contained"
              >
                RE-UPLOAD
              </Button>
            </div>
          )}
          {selectedTab === 0 && isOnboardEnabled && isUploadCompleted && (
            <div>
              <Button
                color="primary"
                disabled={!isSubmitValidBtnDisabled}
                id="uploadButton"
                onClick={handleSuccessDialogOpen}
                variant="contained"
              >
                SUBMIT VALID RECORDS
              </Button>
            </div>
          )}
        </div>
      </div>
      <FileUploadDialog
        data={onboardData}
        handleClose={handleClose}
        isOpen={dialogOpen}
        onUploadComplete={handleUploadComplete}
      />
      <Dialog fullWidth maxWidth="xs" onClose={handleSuccessDialogClose} open={successDialogOpen}>
        <DialogContent style={{ textAlign: 'center' }}>
          <img alt="upload" src="/static/img/submission.svg" style={{ width: '48px', marginBottom: '16px' }} />
          <p style={{ marginBottom: '16px' }}>Submission successful</p>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button color="primary" onClick={handleNavigateToHistory} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

TabPanel.propTypes = {
  fileUploadData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getExceptionData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getProcesseFiledData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleUploadState: PropTypes.func.isRequired,
  initiateImportProcessOperation: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  isOnboardEnabled: PropTypes.bool.isRequired,
  isUploadCompleted: PropTypes.bool.isRequired,
  onboardData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onTabSelect: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setSubmitBtnOperation: PropTypes.func.isRequired,
  submitBtn: PropTypes.bool.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TabPanel;
