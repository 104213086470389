import * as R from 'ramda';

const getRequestType = state => R.pathOr([], ['smdu', 'fnmaRequest'], state);
const getSmduResponseData = state => R.pathOr([], ['smdu', 'smduResponseData'], state);
const getSmduResponseError = state => R.pathOr([], ['smdu', 'smduResponseData', 'errorDetail', 'messages'], state);
const isLoading = state => state.smdu.loading;
const canRunIDI = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRunIDI'], state);
const canRequestModStructuring = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestModStructuring'], state);
const canRequestPreQualification = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestPreQualification'], state);
const canRequestTrialStructuring = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestTrialStructuring'], state);
const canReportToInvestor = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canReportToInvestor'], state);
const getFormattedData = state => R.pathOr([], ['smdu', 'smduHistoryData'], state);
const getFormattedDartsData = state => R.pathOr([], ['smdu', 'smduHistoryDartsData'], state);
const getSmduCaseManagementData = state => R.pathOr([], ['smdu', 'smduCaseManagementData'], state);
const getSMDUPriorWorkoutDropdownOptions = state => R.pathOr([], ['smdu', 'SMDUPriorWorkoutDropdownOptions'], state);
const getReportToInvestor = state => R.pathOr([], ['smdu', 'reportToInvestor'], state);
const isPopUpEnabled = state => state.smdu.isPopUpEnabled;
const getPopUpMessage = state => R.pathOr('', ['smdu', 'popUpMessage'], state);
const getSmduPriorWorkoutHistory = state => R.pathOr([], ['smdu', 'smduPriorWorkoutHistory'], state);
const getPrioWorkoutPopupMessage = state => R.pathOr('', ['smdu', 'prioWorkoutPopupMessage'], state);
const getSmduReportingUpdates = state => R.pathOr('', ['smdu', 'smduReportingUpdates'], state);
const getResolutionType = state => R.pathOr('', ['smdu', 'resolutionType'], state);

const selectors = {
  getRequestType,
  getSmduResponseData,
  getSmduResponseError,
  isLoading,
  getFormattedData,
  getFormattedDartsData,
  canRunIDI,
  canRequestModStructuring,
  canRequestPreQualification,
  canRequestTrialStructuring,
  getSmduCaseManagementData,
  getSMDUPriorWorkoutDropdownOptions,
  getReportToInvestor,
  isPopUpEnabled,
  getPopUpMessage,
  getSmduPriorWorkoutHistory,
  canReportToInvestor,
  getPrioWorkoutPopupMessage,
  getSmduReportingUpdates,
  getResolutionType,
};

export default selectors;
