import React, { useState } from 'react';
// import { useDispatch } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './RightPane.css';
import PropTypes from 'prop-types';
import DateRangePicker from './DateRangePicker';


const RightPane = ({ setFilterData, userNames }) => {
  // const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [selectedUser, setUser] = useState('All');
  const [selectedFile, setFileName] = useState('All');

  const handleApplyButton = (updatedStartDate, updatedEndDate, updatedUser) => {
    if (updatedEndDate && !updatedStartDate) {
      setButtonDisabled(true);
    } else if (updatedUser) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    handleApplyButton(date, endDate, selectedUser);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    handleApplyButton(startDate, date, selectedUser);
  };

  const handleUserName = (event, newValue) => {
    const updatedUser = newValue || 'All';
    setUser(updatedUser);
    handleApplyButton(startDate, endDate, updatedUser);
  };


  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setUser('All');
    setFileName('All');
    handleApplyButton(null);
  };

  const handleApply = () => {
    setFilterData({
      startDate,
      endDate,
      selectedFile,
      selectedUser,
    });
  };

  return (
    <div styleName="rightPane">
      <div styleName="container">
        <img alt="FilterImage" src="/static/img/filter.svg" style={{ marginRight: '5px' }} />
        <span style={{ flexGrow: 1 }} styleName="typography">FILTER</span>
      </div>
      <div styleName="dateContainer">
        <span styleName="typography">Date Range</span>
        <DateRangePicker
          endDate1={endDate}
          handleEndDate={handleEndDate}
          handleStartDate={handleStartDate}
          startDate1={startDate}
        />
      </div>

      <div>
        <span styleName="typography">User Name</span>
        <Autocomplete
          onChange={handleUserName}
          options={userNames}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Type to search"
              size="small"
              variant="outlined"
            />
          )}
          value={selectedUser}
        />
      </div>
      <div styleName="block">
        <div styleName="endcontainer">
          <Button
            className="material-ui-button"
            color="primary"
            disabled={isButtonDisabled}
            id="CLEAR"
            onClick={handleClear}
            variant="text"
          >
            <span styleName="typography">CLEAR</span>
          </Button>
          <Button
            className="material-ui-button"
            color="primary"
            disabled={isButtonDisabled}
            id="APPLY"
            onClick={handleApply}
            variant="contained"
          >
            <span styleName="typography">APPLY</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

RightPane.defaultProps = {
  userNames: [],
};

RightPane.propTypes = {
  history: PropTypes.shape().isRequired,
  setFilterData: PropTypes.func.isRequired,
  userNames: PropTypes.shape(),
};

export default RightPane;
