import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import * as R from 'ramda';
import './MUITable.css';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/BoardingTemplate';


function EnhancedTableHead(props) {
  const {
    order, onRequestSort, columns,
  } = props;
  const createSortHandler = (newOrderBy, canSort) => (event) => {
    if (canSort) onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        {columns && columns.map(headCell => (
          <TableCell
            key={headCell.name}
            align={headCell.align}
            sortDirection={order[headCell.name] ? order[headCell.name] : false}
          >
            <TableSortLabel
              active={order[headCell.name]}
              classes={{ root: 'table-header' }}
              direction={order[headCell.name] ? order[headCell.name] : 'asc'}
              hideSortIcon={!headCell.options.sort}
              IconComponent={UnfoldMoreIcon}
              onClick={createSortHandler(headCell.name, headCell.options.sort)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.shape({}).isRequired,
};

function BoardingTemplateHistoryTable(props) {
  const {
    columns, data, handleDownload, handleChange, sortCriteria, filters,
  } = props;

  const processData = () => data && data.records.map((item) => {
    let newObject = {};
    columns.forEach(
      (obj) => {
        newObject = R.assoc(
          obj.name,
          obj.cellFormat ? obj.cellFormat(obj.name === 'download' ? { fn: handleDownload, id: item.id, importStatus: item.importStatus } : item[obj.name]) : item[obj.name],
          newObject,
        );
        return newObject;
      },
    );
    return newObject;
  });

  const [order, setOrder] = React.useState(sortCriteria);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const [currentTableFilter, setCurrentTableFilter] = React.useState({
    initialData: processData(),
  });

  React.useEffect(() => {
    const newState = {};
    setCurrentTableFilter({
      ...newState,
      ...currentTableFilter,
    });
  }, []);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      let toggledOrder;
      if (newOrderBy === 'createdAt') {
        const isDesc = order[newOrderBy] && order[newOrderBy] === 'desc';
        toggledOrder = isDesc ? 'asc' : 'desc';
      } else {
        const isAsc = order[newOrderBy] && order[newOrderBy] === 'asc';
        toggledOrder = isAsc ? 'desc' : 'asc';
      }
      const sortOrder = { [newOrderBy]: toggledOrder };
      setOrder(sortOrder);
      handleChange(page, R.propOr(DEFAULT_ROWS_PER_PAGE, 'pageSize', data), filters, sortOrder);
    },
    [order, page, rowsPerPage],
  );

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);
      handleChange(newPage, R.propOr(DEFAULT_ROWS_PER_PAGE, 'pageSize', data), filters, order);
    },
    [order, rowsPerPage],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
      handleChange(0, +event.target.value, filters, order);
    },
    [order],
  );

  return (
    <div>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          backgroundColor="#F3F5F9"
          size="small"
          sx={{ minWidth: 750 }}
        >
          <EnhancedTableHead
            columns={columns}
            onRequestSort={handleRequestSort}
            order={order}
          />
          <TableBody>
            {currentTableFilter && currentTableFilter.initialData
              ? currentTableFilter.initialData.map(row => (
                <TableRow
                  key={row.name}
                  hover
                  role="checkbox"
                  sx={{ cursor: 'pointer' }}
                  tabIndex={-1}
                >
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell key={key} align="left" id={`${key}_${value}`}>
                      {value || ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={R.propOr(0, 'totalRecords', data)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={R.propOr(0, 'page', data)}
        rowsPerPage={R.propOr(DEFAULT_ROWS_PER_PAGE, 'pageSize', data)}
        rowsPerPageOptions={[500]}
      />
    </div>
  );
}
BoardingTemplateHistoryTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
  data: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape).isRequired,
  }).isRequired,
  filters: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  sortCriteria: PropTypes.shape({}).isRequired,
};
export default BoardingTemplateHistoryTable;
