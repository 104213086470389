
import {
  fetchBoardingTemplateHistoryAction,
  submitBoardingTemplateAction,
  submitOnBoardTemplateAction,
  processBoardingTemplateAction,
  initiateImportProcessAction,
  setCaseTypesAction,
  setBoardingPhasesAction,
  downloadFileAction,
  setSubmitBtnAction,
  clearDataAction,
} from './actions';

const fetchBoardingTemplateHistory = dispatch => (pageNumber, pageSize, filter, order) => {
  dispatch(fetchBoardingTemplateHistoryAction(pageNumber, pageSize, filter, order));
};

const validateBoardingTemplateOperation = dispatch => () => {
  dispatch(submitBoardingTemplateAction());
};

const onBoardTemplate = dispatch => (onBoardData) => {
  dispatch(submitOnBoardTemplateAction(onBoardData));
};

const proceBoardingTemplateOperation = dispatch => (onboardTemplate, file) => {
  dispatch(processBoardingTemplateAction(onboardTemplate, file));
};

const initiateImportProcessOperation = dispatch => (validRecords) => {
  dispatch(initiateImportProcessAction(validRecords));
};

const setCaseTypesOperation = dispatch => () => {
  dispatch(setCaseTypesAction());
};

const setBoardingPhasesOperation = dispatch => (caseType) => {
  dispatch(setBoardingPhasesAction(caseType));
};

const downloadFile = dispatch => (payload) => {
  dispatch(downloadFileAction(payload));
};

const setSubmitBtnOperation = dispatch => (payload) => {
  dispatch(setSubmitBtnAction(payload));
};

const clearDataOperation = dispatch => () => {
  dispatch(clearDataAction());
};

const operations = {
  fetchBoardingTemplateHistory,
  validateBoardingTemplateOperation,
  onBoardTemplate,
  proceBoardingTemplateOperation,
  initiateImportProcessOperation,
  setCaseTypesOperation,
  setBoardingPhasesOperation,
  downloadFile,
  setSubmitBtnOperation,
  clearDataOperation,
};

export default operations;
