/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import hotkeys from 'hotkeys-js';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import TaskPane from 'containers/Dashboard/TaskPane';
import Checklist from 'components/Checklist';
import Loader from 'components/Loader/Loader';
import CustomSnackBar from 'components/CustomSnackBar';
import AdditionalInfo from 'containers/AdditionalInfo';
import DashboardModel from 'models/Dashboard';
import { withRouter } from 'react-router-dom';
import {
  ERROR, SUCCESS, financialChecklist, checklistForms,
} from 'constants/common';
import UserNotification from 'components/UserNotification/UserNotification';
import DispositionModel from 'models/Disposition';
import ChecklistErrorMessageCodes from 'models/ChecklistErrorMessageCodes';
import { selectors as notificationSelectors, operations as notificationOperations } from 'ducks/notifications';
import { selectors as incomeSelectors } from 'ducks/income-calculator';
import { selectors as accountServiceSelectors } from 'ducks/accountService';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import { selectors as widgetsSelectors, operations as widgetsOperations } from 'ducks/widgets';
import { operations, selectors } from 'ducks/tasks-and-checklist';
import { selectors as loginSelectors } from 'ducks/login';
import { selectors as stagerSelectors } from 'ducks/stager';
import { closeWidgets } from 'components/Widgets/WidgetSelects';
import ErrorBanner from 'components/ErrorBanner';
import componentTypes from 'constants/componentTypes';
import IncomeCalcWidget from 'containers/IncomeCalc/IncomeCalcWidget';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import Grid from '@material-ui/core/Grid';
import {
  BOOKING, HISTORY, ADDITIONAL_INFO, FINANCIAL_CALCULATOR, DOCUMENT_CHECKLIST,
  FHLMC, LSAMS_NOTES, EVAL, WESTWING, PAYMENT_SUPPLEMENT, SMDU,
} from 'constants/widgets';
import { EDITABLE_FIELDS } from 'constants/loanInfoComponents';
import getTombstonePopup from 'components/Tombstone/PopupSelect';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import DocChecklistWidget from 'components/Widgets/DocChecklistWidget';
import EvalWidget from 'components/Widgets/EvalWidget';
import WestWingWidget from 'components/Widgets/WestWing';
import PaymentSupplement from 'components/Widgets/PaymentSupplement';
import Popup from '../../../components/Popup';
import MilestoneActivity from '../../LoanActivity/MilestoneActivity';
import WidgetBuilder from '../../../components/Widgets/WidgetBuilder';
import Navigation from './Navigation';
import DialogCard from './DialogCard';
import styles from './TasksAndChecklist.css';
import FHLMCWidget from '../../../components/Widgets/FHLMCWidget';
import SMDUWidget from '../../../components/Widgets/SMDUWidget';

const { Messages: { MSG_NO_TASKS_FOUND, MSG_TASK_FETCH_ERROR } } = DashboardModel;

const LEFT_KEY = 37;
const RIGHT_KEY = 39;
class TasksAndChecklist extends Component {
  componentDidMount() {
    const {
      onWidgetToggle, groupName, openWidgetList,
    } = this.props;
    hotkeys('right,left', (event) => {
      if (event.type === 'keydown') {
        this.handleHotKeyPress();
      }
    });
    if (R.equals(groupName, DashboardModel.BOOKING)
      && !(R.contains(ADDITIONAL_INFO, openWidgetList)
        || R.contains(HISTORY, openWidgetList) || R.contains(LSAMS_NOTES, openWidgetList)
        || R.contains(PAYMENT_SUPPLEMENT, openWidgetList))) {
      const payload = {
        currentWidget: BOOKING,
        openWidgetList: [BOOKING],
      };
      onWidgetToggle(payload);
    }
  }

  componentWillUnmount() {
    hotkeys.unbind('right,left');
    const {
      dashboardResetDataOperation,
      openWidgetList,
    } = this.props;
    if (R.contains(BOOKING, openWidgetList)) {
      this.handleClose();
    }
    dashboardResetDataOperation();
  }

  getChecklistItems() {
    const {
      filter, checklistItems, passedRules, failedRules,
    } = this.props;
    if (R.isNil(filter)) {
      return checklistItems;
    }
    return filter ? passedRules : failedRules;
  }

  shouldRenderWidgetView = () => {
    const { openWidgetList, groupName } = this.props;
    const isLossMittAgentCalcWidget = R.contains(FINANCIAL_CALCULATOR, openWidgetList)
      && groupName === DashboardModel.SEARCH_LOAN;
    if (isLossMittAgentCalcWidget) {
      return false;
    }
    return R.any(widget => R.contains(
      widget, [HISTORY, ADDITIONAL_INFO,
        FINANCIAL_CALCULATOR, DOCUMENT_CHECKLIST, FHLMC, LSAMS_NOTES,
        WESTWING, EVAL, PAYMENT_SUPPLEMENT, SMDU],
    ))(openWidgetList);
  }

  handleHotKeyPress = () => {
    const {
      disableNext,
      disablePrev,
      onNext,
      onPrev,
    } = this.props;
    if (!disableNext && hotkeys.getPressedKeyCodes().includes(RIGHT_KEY)) {
      onNext();
    } else if (!disablePrev && hotkeys.getPressedKeyCodes().includes(LEFT_KEY)) {
      onPrev();
    }
  }

  handleClose = () => {
    const { onWidgetToggle, openWidgetList, groupName } = this.props;
    const widgetsToBeClosed = {
      openWidgetList,
      page: groupName,
      closingWidgets: [BOOKING],
    };
    const payload = {
      currentWidget: '',
      openWidgetList: closeWidgets(widgetsToBeClosed),
    };
    onWidgetToggle(payload);
  }

  handleSubTaskClearance(isConfirmed) {
    if (isConfirmed) {
      const {
        handleClearSubTask,
        rootTaskId, selectedTaskId,
        selectedTaskBlueprintCode,
      } = this.props;
      handleClearSubTask(selectedTaskId, rootTaskId, selectedTaskBlueprintCode);
    }
  }

  renderTaskErrorMessage() {
    const { checklistErrorMessage } = this.props;
    if (checklistErrorMessage) {
      return (
        <div styleName="notificationMsg">
          <UserNotification
            level="error"
            message={checklistErrorMessage}
            type="alert-box"
          />
        </div>
      );
    }
    return null;
  }


  renderChecklist() {
    const {
      checklistItems,
      checklistTitle,
      dataLoadStatus,
      message,
      onChecklistChange,
      disposition,
      enableGetNext, isAssigned, noTasksFound, taskFetchError,
      user,
      showAssign,
      isDialogOpen,
      getDialogContent,
      dialogTitle,
      handleDeleteTask,
      handleShowDeleteTaskConfirmation,
      failedRules,
      passedRules,
      location,
      resolutionId,
      groupName,
      resolutionData,
      closeSweetAlert,
      putComputeRulesPassed,
      ruleResultFromTaskTree,
      getSelectedWidget,
      openWidgetList,
      incomeCalcInProgress,
      taskName,
      delayChecklistHistory,
      processAction,
      disableNext,
      disablePrev,
      onNext,
      onPrev,
      isValidateLoading,
    } = this.props;

    if (isValidateLoading) {
      return <Loader message="Please Wait" />;
    }
    if (dataLoadStatus === 'failed') {
      return <ErrorIcon fontSize="large" styleName="error-indicator" />;
    }
    if (checklistItems && checklistItems.length <= 0) {
      return null;
    }
    let notification;
    if (!message || message.type === 'do-not-display') {
      notification = null;
    } else {
      notification = DashboardModel.Messages.renderErrorNotification(
        disposition,
        enableGetNext, isAssigned, noTasksFound, taskFetchError,
        message.msg,
        user,
        showAssign,
      );
    }
    let styleName = 'checklist';
    const checklistType = checklistItems && R.pathOr('', ['additionalInfo', 'checklistType'], R.head(checklistItems));
    const checklistCustomType = checklistItems && R.pathOr('', ['additionalInfo', 'customType'], R.head(checklistItems));
    if (financialChecklist.includes(checklistType)) {
      styleName = 'incomeCalc';
    }
    if (checklistItems && (R.equals(R.prop('checklistType', R.head(checklistItems).additionalInfo), 'asset-verification')) && (R.equals(R.prop('checklistType', R.head(checklistItems).additionalInfo), 'fico-score'))) {
      styleName = 'incomeCalc-av';
    }

    if (checklistForms.includes(checklistCustomType) || checklistForms.includes(checklistType)) {
      styleName = 'incomeCalc-av';
    }
    const isBookingWidgetOpen = R.contains(BOOKING, openWidgetList);
    if (groupName === DashboardModel.BOOKING || isBookingWidgetOpen) {
      styleName = 'sla-rules';
      if (checklistItems && checklistItems[0].showPushData) {
        styleName = 'pushData';
      }
    }

    return (
      <Checklist
        checklistItems={this.getChecklistItems()}
        closeSweetAlert={closeSweetAlert}
        delayChecklistHistory={delayChecklistHistory}
        dialogContent={getDialogContent}
        dialogTitle={dialogTitle}
        disableNext={disableNext}
        disablePrev={disablePrev}
        failedRules={failedRules}
        groupName={groupName}
        handleClearSubTask={isConfirmed => this.handleSubTaskClearance(isConfirmed)}
        handleDeleteTask={handleDeleteTask}
        handleShowDeleteTaskConfirmation={handleShowDeleteTaskConfirmation}
        incomeCalcInProgress={incomeCalcInProgress}
        isDialogOpen={isDialogOpen}
        location={location}
        onChange={onChecklistChange}
        onCompleteMyReviewClick={this.handleClose}
        onNext={onNext}
        onPrev={onPrev}
        passedRules={passedRules}
        processAction={processAction}
        putComputeRulesPassed={putComputeRulesPassed}
        resolutionData={resolutionData}
        resolutionId={resolutionId}
        ruleResultFromTaskTree={ruleResultFromTaskTree}
        selectedWidget={getSelectedWidget}
        styleName={styleName}
        taskName={taskName}
        title={checklistTitle}
        triggerHeader={isBookingWidgetOpen}
      >
        {notification}
      </Checklist>
    );
  }

  renderSnackBar() {
    const { snackBarData, closeSnackBar } = this.props;
    return (
      <CustomSnackBar
        message={snackBarData && snackBarData.message}
        onClose={closeSnackBar}
        open={snackBarData && snackBarData.open}
        type={snackBarData && snackBarData.type}
      />
    );
  }

  renderWidgetComponents() {
    const { openWidgetList, groupName } = this.props;
    const mainWidget = R.head(openWidgetList);
    let widgetToRender = null;
    switch (mainWidget) {
      case ADDITIONAL_INFO:
        widgetToRender = <AdditionalInfo groupName={groupName} />;
        break;
      case HISTORY:
        widgetToRender = <MilestoneActivity />;
        break;
      case FINANCIAL_CALCULATOR:
        widgetToRender = <IncomeCalcWidget />;
        break;
      case DOCUMENT_CHECKLIST:
        widgetToRender = <DocChecklistWidget />;
        break;
      case FHLMC:
        widgetToRender = <FHLMCWidget />;
        break;
      case LSAMS_NOTES:
        widgetToRender = <LSAMSNotesWidget />;
        break;
      case WESTWING:
        widgetToRender = <WestWingWidget />;
        break;
      case EVAL:
        widgetToRender = <EvalWidget />;
        break;
      case SMDU:
        widgetToRender = <SMDUWidget />;
        break;
      case PAYMENT_SUPPLEMENT:
        widgetToRender = <PaymentSupplement />;
        break;
      default:
        widgetToRender = null;
    }
    return widgetToRender;
  }

  renderSweetAlert() {
    const { clearPopupData, popupData, dispatchAction } = this.props;
    if (popupData) {
      const {
        isOpen, message, title, level, showCancelButton,
        cancelButtonText, confirmButtonText, onConfirm,
      } = popupData;
      const confirmAction = onConfirm ? dispatchAction : clearPopupData;
      return (
        <Popup
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          level={level}
          message={message}
          onCancel={clearPopupData}
          onConfirm={() => confirmAction(onConfirm)}
          show={isOpen}
          showCancelButton={showCancelButton}
          showConfirmButton
          title={title}
        />
      );
    }
    return null;
  }


  renderCenterPaneChecklist() {
    const {
      commentsRequired,
      instructions,
      disposition,
      groupName,
      onInstuctionDialogToggle,
      showDisposition,
      showInstructionsDialog,
      isAssigned,
      openWidgetList,
      isAccountServiceScreenVisible,
      stagerTaskName,
    } = this.props;
    const showDialogBox = (isAssigned && showDisposition);
    // const bookingHomepageMsg = (isAssigned === true) ? 'Booking Widget' : 'Assign to me';
    const dispositionMessage = R.is(Array, disposition) ? R.join(',', disposition) : disposition;
    const taskPane = R.contains(FINANCIAL_CALCULATOR, openWidgetList) ? null : <TaskPane styleName="tasks" />;
    const currentOverlay = (this.shouldRenderWidgetView() || isAccountServiceScreenVisible)
      ? null : (
        <>
          {taskPane}
          {this.renderChecklist()}
        </>
      );
    const activeTile = R.propOr('', 'activeTile', stagerTaskName);
    const activeTab = R.propOr('', 'activeTab', stagerTaskName);
    const isDelayChecklistToOrderTile = (activeTile === 'Delay Checklist') && (activeTab === 'To Order');
    const isLossMittAgentCalcWidget = (R.contains(FINANCIAL_CALCULATOR, openWidgetList)
      && groupName === DashboardModel.SEARCH_LOAN);
    return (
      <section style={{ scrollbarWidth: 'thin' }} styleName={R.contains(HISTORY, openWidgetList) ? 'tasks-and-checklist-loan-audit' : 'tasks-and-checklist'}>
        {currentOverlay}
        {this.renderSnackBar()}
        {!this.shouldRenderWidgetView() && !isAccountServiceScreenVisible ? (
          <>
            {!isLossMittAgentCalcWidget
              && (
                <DialogCard
                  commentsRequired={commentsRequired}
                  dialogContent={instructions}
                  dialogHeader="Steps to Resolve"
                  message={dispositionMessage}
                  onDialogToggle={onInstuctionDialogToggle}
                  shouldShow={showDialogBox}
                  showDialog={showInstructionsDialog}
                  styleName={isDelayChecklistToOrderTile ? 'instructions-delayChkList' : 'instructions'}
                  title="Disposition"
                />
              )
            }
            <Navigation
              className={classNames(styles.footer, styles.navigation)}
            />
          </>
        ) : this.renderWidgetComponents()}
        <WidgetBuilder
          page={groupName}
          styleName={groupName === DashboardModel.DOCS_IN
            && R.contains(BOOKING, openWidgetList)
            ? 'task-checklist-bw' : 'task-checklist'}
        />
        {this.renderSweetAlert()}
      </section>
    );
  }


  renderLoanInfoComponents() {
    const { groupName, openWidgetList, checklistCenterPaneView } = this.props;
    return (
      <section styleName="loanInfo">
        <Grid styleName="rfdData">
          {getTombstonePopup(checklistCenterPaneView)}
        </Grid>
        <WidgetBuilder
          page={groupName}
          styleName={groupName === DashboardModel.DOCS_IN
            && R.contains(BOOKING, openWidgetList)
            ? 'task-checklist-bw' : 'task-checklist'}
        />
        {this.renderSweetAlert()}
      </section>

    );
  }

  render() {
    const {
      inProgress,
      noTasksFound,
      taskFetchError,
      isGetNextError,
      isPostModEndShift,
      completeReviewResponse,
      history,
      errorBanner,
      showBanner,
      checklistCenterPaneView,
    } = this.props;
    if (isPostModEndShift) {
      history.push('/stager');
    }
    if (completeReviewResponse && !R.prop(ERROR, completeReviewResponse)) {
      history.push('/special-loan');
    }
    if (inProgress) {
      return (
        <Loader message="Please Wait" />
      );
    }

    if (EDITABLE_FIELDS.includes(checklistCenterPaneView)) {
      return (<div styleName="scroll-wrapper">{this.renderLoanInfoComponents()}</div>);
    }

    if (noTasksFound || taskFetchError || isGetNextError) {
      return (
        this.renderTaskErrorMessage()
      );
    }

    return (
      <div styleName="scroll-wrapper">
        {showBanner && <ErrorBanner errorBanner={errorBanner} />}
        {this.renderCenterPaneChecklist()}
      </div>
    );
  }
}

TasksAndChecklist.defaultProps = {
  showBanner: false,
  enableGetNext: false,
  filter: null,
  inProgress: false,
  incomeCalcInProgress: false,
  message: null,
  noTasksFound: false,
  isGetNextError: false,
  taskFetchError: false,
  isDialogOpen: false,
  dialogTitle: '',
  getDialogContent: '',
  snackBarData: null,
  showAssign: false,
  isPostModEndShift: false,
  resolutionData: [],
  ruleResultFromTaskTree: [],
  openWidgetList: [],
  checklistCenterPaneView: 'Checklist',
  stagerTaskName: '',
  isValidateLoading: false,
};

TasksAndChecklist.propTypes = {
  assignResult: PropTypes.shape({
    cmodProcess: PropTypes.shape({
      taskStatus: PropTypes.string.isRequired,
    }),
    status: PropTypes.string,
    statusCode: PropTypes.string,
    taskData: PropTypes.shape({
      evalId: PropTypes.string.isRequired,
      groupName: PropTypes.string.isRequired,
      loanNumber: PropTypes.string.isRequired,
      processStatus: PropTypes.string.isRequired,
      taskCheckListId: PropTypes.string.isRequired,
      taskCheckListTemplateName: PropTypes.string.isRequired,
      wfProcessId: PropTypes.string.isRequired,
      wfTaskId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  checklistCenterPaneView: PropTypes.string,
  checklistErrorMessage: PropTypes.string.isRequired,
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      showPushData: PropTypes.bool,
      taskCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(componentTypes)).isRequired,
    }),
  ).isRequired,
  checklistTitle: PropTypes.string.isRequired,
  clearPopupData: PropTypes.func.isRequired,
  closeSnackBar: PropTypes.func.isRequired,
  closeSweetAlert: PropTypes.func.isRequired,
  commentsRequired: PropTypes.bool.isRequired,
  completeReviewResponse: PropTypes.shape().isRequired,
  dashboardResetDataOperation: PropTypes.func.isRequired,
  dataLoadStatus: PropTypes.string.isRequired,
  delayChecklistHistory: PropTypes.arrayOf(PropTypes.shape({
    completedByUserName: PropTypes.string,
    completedDate: PropTypes.string,
    delayCheckListReason: PropTypes.string,
    evalId: PropTypes.int,
    letterExpiryDate: PropTypes.string,
    letterSentDate: PropTypes.string,
    loanNumber: PropTypes.string,
    recordCreatedByUser: PropTypes.string,
    recordCreatedDate: PropTypes.string,
    taskId: PropTypes.string,
  })).isRequired,
  dialogTitle: PropTypes.string,
  disableNext: PropTypes.bool.isRequired,
  disablePrev: PropTypes.bool.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  disposition: PropTypes.oneOfType([
    PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  enableGetNext: PropTypes.bool,
  errorBanner: PropTypes.shape({
    errors: PropTypes.array,
    warnings: PropTypes.array,
  }).isRequired,
  failedRules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filter: PropTypes.bool,
  getDialogContent: PropTypes.string,
  getSelectedWidget: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  handleClearSubTask: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleShowDeleteTaskConfirmation: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  incomeCalcInProgress: PropTypes.bool,
  inProgress: PropTypes.bool,
  instructions: PropTypes.string.isRequired,
  isAccountServiceScreenVisible: PropTypes.bool.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool,
  isGetNextError: PropTypes.bool,
  isPostModEndShift: PropTypes.bool,
  isValidateLoading: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string.isRequired,
  }).isRequired,
  message: PropTypes.shape({
    msg: PropTypes.string,
    type: PropTypes.string,
  }),
  noTasksFound: PropTypes.bool,
  onChecklistChange: PropTypes.func.isRequired,
  onInstuctionDialogToggle: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onWidgetToggle: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  passedRules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  processAction: PropTypes.func.isRequired,
  putComputeRulesPassed: PropTypes.func.isRequired,
  resolutionData: PropTypes.arrayOf(PropTypes.string),
  resolutionId: PropTypes.string.isRequired,
  rootTaskId: PropTypes.string.isRequired,
  ruleResultFromTaskTree: PropTypes.arrayOf(PropTypes.shape),
  selectedTaskBlueprintCode: PropTypes.string.isRequired,
  selectedTaskId: PropTypes.string.isRequired,
  showAssign: PropTypes.bool,
  showBanner: PropTypes.bool,
  showDisposition: PropTypes.bool.isRequired,
  showInstructionsDialog: PropTypes.bool.isRequired,
  snackBarData: PropTypes.shape(),
  stagerTaskName: PropTypes.string,
  taskFetchError: PropTypes.bool,
  taskName: PropTypes.string.isRequired,
  user: PropTypes.shape({
    skills: PropTypes.array.isRequired,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

function getUserNotification(message) {
  if (message.type === SUCCESS) {
    return message;
  }
  if (message.type === ERROR) {
    return {
      type: ERROR,
      msg: R.has('data', message) ? DispositionModel.getErrorMessages(message.data)
        : R.has('rulesData', message) ? DispositionModel.getErrorMsgFromRulesEngine(message.rulesData)
          : message.msg,
    };
  }
  return {
    type: 'do-not-display',
  };
}

function getChecklistErrorMessage(checklistErrorCode, taskFetchError,
  noTasksFound, isGetNextError, getNextError) {
  if ((!(taskFetchError || noTasksFound)) && !isGetNextError) {
    return '';
  }
  switch (checklistErrorCode) {
    case ChecklistErrorMessageCodes.NO_CHECKLIST_ID_PRESENT:
      return 'Checklist not found.';
    case ChecklistErrorMessageCodes.CHECKLIST_FETCH_FAILED:
      return 'Checklist fetch failed. Please try again later.';
    default:
      break;
  }
  if (taskFetchError) {
    return MSG_TASK_FETCH_ERROR;
  }
  if (noTasksFound) {
    return MSG_NO_TASKS_FOUND;
  }
  if (isGetNextError) {
    return getNextError;
  }
  return '';
}

function mapStateToProps(state) {
  const noTasksFound = dashboardSelectors.noTasksFound(state);
  const taskFetchError = dashboardSelectors.taskFetchError(state);
  const checklistErrorCode = dashboardSelectors.getChecklistErrorCode(state);
  const isGetNextError = dashboardSelectors.isGetNextError(state);
  const getNextError = dashboardSelectors.getNextError(state);

  return {
    incomeCalcInProgress: incomeSelectors.inProgress(state),
    openWidgetList: widgetsSelectors.getOpenWidgetList(state),
    disposition: selectors.getDisposition(state),
    dataLoadStatus: selectors.getChecklistLoadStatus(state),
    checklistErrorMessage: getChecklistErrorMessage(
      checklistErrorCode,
      taskFetchError,
      noTasksFound,
      isGetNextError,
      getNextError,
    ),
    assignResult: dashboardSelectors.assignResult(state),
    rootTaskId: selectors.getRootTaskId(state),
    commentsRequired: selectors.showComment(state),
    snackBarData: notificationSelectors.getSnackBarState(state),
    checklistItems: selectors.getChecklistItems(state),
    checklistTitle: selectors.getChecklistTitle(state),
    disableNext: selectors.shouldDisableNext(state),
    disablePrev: selectors.shouldDisablePrev(state),
    enableGetNext: dashboardSelectors.enableGetNext(state),
    isAssigned: dashboardSelectors.isAssigned(state),
    groupName: dashboardSelectors.groupName(state),
    inProgress: dashboardSelectors.inProgress(state),
    isPostModEndShift: dashboardSelectors.isPostModEndShift(state),
    completeReviewResponse: dashboardSelectors.completeReviewResponse(state),
    instructions: selectors.getInstructions(state),
    message: getUserNotification(dashboardSelectors.getChecklistDiscrepancies(state)),
    noTasksFound,
    isGetNextError,
    showAssign: dashboardSelectors.showAssign(state),
    getNextError,
    showDisposition: selectors.shouldShowDisposition(state),
    showInstructionsDialog: selectors.shouldShowInstructionsDialog(state),
    taskFetchError,
    user: loginSelectors.getUser(state),
    isDialogOpen: selectors.isDialogOpen(state),
    getDialogContent: selectors.getDialogContent(state),
    dialogTitle: selectors.getDialogTitle(state),
    selectedTaskId: selectors.selectedTaskId(state),
    selectedTaskBlueprintCode: selectors.selectedTaskBlueprintCode(state),
    getSelectedWidget: dashboardSelectors.getSelectedWidget(state),
    passedRules: selectors.getPassedRules(state),
    failedRules: selectors.getFailedRules(state),
    filter: selectors.getFilter(state),
    resolutionId: selectors.getResolutionId(state),
    resolutionData: dashboardSelectors.getResolutionData(state),
    prevDocsInChecklistId: selectors.getPrevDocsInChecklistId(state),
    prevDocsInRootTaskId: selectors.getPrevDocsInRootTaskId(state),
    errorBanner: incomeSelectors.getErrorBanner(state),
    showBanner: dashboardSelectors.showBanner(state),
    ruleResultFromTaskTree: selectors.getRuleResultFromTaskTree(state),
    popupData: dashboardSelectors.getPopupData(state),
    loanNumber: dashboardSelectors.loanNumber(state),
    taskName: stagerSelectors.getTaskName(state),
    delayChecklistHistory: stagerSelectors.getDelayCheckListHistory(state),
    checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
    isAccountServiceScreenVisible: accountServiceSelectors.isAccountServiceScreenVisible(state),
    stagerTaskName: dashboardSelectors.stagerTaskName(state),
    isValidateLoading: dashboardSelectors.getIsValidateLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    processAction: operations.preProcessChecklistItems(dispatch),
    onWidgetToggle: widgetsOperations.onWidgetToggle(dispatch),
    onChecklistChange: operations.handleChecklistItemValueChange(dispatch),
    closeSnackBar: notificationOperations.closeSnackBar(dispatch),
    handleDeleteTask: operations.handleDeleteTask(dispatch),
    handleShowDeleteTaskConfirmation: operations.handleShowDeleteTaskConfirmation(dispatch),
    onNext: operations.fetchNextChecklist(dispatch),
    onPrev: operations.fetchPrevChecklist(dispatch),
    onInstuctionDialogToggle: operations.handleToggleInstructions(dispatch),
    handleClearSubTask: operations.handleSubTaskClearance(dispatch),
    onWidgetClick: dashboardOperations.onWidgetClick(dispatch),
    closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
    clearPopupData: dashboardOperations.clearPopupData(dispatch),
    putComputeRulesPassed: operations.putComputeRulesPassed(dispatch),
    dispatchAction: dashboardOperations.dispatchAction(dispatch),
    dashboardResetDataOperation: dashboardOperations.dashboardResetDataOperation(dispatch),
  };
}

const TestHooks = {
  TasksAndChecklist,
  getUserNotification,
  getChecklistErrorMessage,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksAndChecklist));

export { TestHooks };
