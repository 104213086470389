/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import './CaseRejectPopUp.css';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/eval';
import * as R from 'ramda';
import { REASON_CODE_VALID_INPUT } from '../../../constants/eval';

const CaseRejectPopUp = (props) => {
  const {
    isOpen, handleClose, rejectReasons, rejectSubReasons,
    rejectCase, caseId,
    fetchRejectSubReasons, inProgress,
  } = props;
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonIdInput, setReasonIdInput] = useState('');
  const [subReasonIdInput, setSubReasonIdInput] = useState('');
  const [selectedSubRsn, setSelectedSubRsn] = useState('');

  const handleReasonChange = (event) => {
    setSelectedSubRsn('');
    setSelectedReason(event.target.value);
    const option = event.target.value.split('_');
    fetchRejectSubReasons({ reasonId: option[0] });
  };

  const handleSubReasonChng = (event) => {
    setSelectedSubRsn(event.target.value);
  };

  const handleReject = () => {
    const reasonId = selectedReason.split('_');
    const subReasonId = selectedSubRsn.split('_');
    rejectCase({
      caseId, reasonId: reasonId[0], reason: reasonId[1], subReasonId: subReasonId[0],
    });
    handleClose();
  };

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      if (event.target.name === 'reason') {
        setReasonIdInput('');
      } else {
        setSubReasonIdInput('');
      }
    } else if (key === 'Backspace') {
      if (reasonIdInput.length > 0) {
        if (event.target.name === 'reason') {
          setReasonIdInput(reasonIdInput.slice(0, -1));
        } else {
          setSubReasonIdInput(subReasonIdInput.slice(0, -1));
        }
      }
    } else if (key in REASON_CODE_VALID_INPUT) {
      if (event.target.name === 'reason') {
        setReasonIdInput(reasonIdInput + key);
      } else {
        setSubReasonIdInput(subReasonIdInput + key);
      }
    }
  };

  const filterReasonsByPrefix = () => {
    if (reasonIdInput === '') {
      return rejectReasons;
    }
    return rejectReasons.filter(reason => R.pathOr('', ['reasonCd'], reason).toString().startsWith(reasonIdInput));
  };

  const filterSubReasonsByPrefix = () => {
    if (subReasonIdInput === '') {
      return rejectSubReasons;
    }
    return rejectSubReasons.filter(subRsn => R.pathOr('', ['subReasonCd'], subRsn).toString().startsWith(subReasonIdInput));
  };

  return (
    <Grid>
      <Dialog open={isOpen} styleName="casereject-dialog">
        {inProgress && (
          <div styleName="caseRejectLoader">
            <Loader message="Please wait" size={10} />
          </div>
        )}
        <DialogTitle>
          <Grid container item>
            <Grid>
              <Typography styleName="reject-reason-title">
                Reject Reason
              </Typography>
            </Grid>
            <Grid>
              <IconButton onClick={handleClose} styleName="reject-reason-close-icon">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ overflow: 'hidden' }}>
            <Grid item styleName="reason-label" xs={3}>
              <div>
                Reason
              </div>
            </Grid>
            <Grid item style={{ overflow: 'hidden' }} xs={9}>
              <div onKeyDown={handleKeyDown}>
                <Select
                  disableUnderline
                  displayEmpty
                  fullWidth
                  input={<OutlinedInput name="selectedRejectedReason" />}
                  name="reason"
                  onChange={handleReasonChange}
                  styleName="casereject-reason-dropDown"
                  value={selectedReason}
                >
                  <MenuItem disabled value="">
              Select
                  </MenuItem>
                  {rejectReasons && filterReasonsByPrefix().map(({ reason, reasonCd }) => (
                    <MenuItem key={reasonCd} value={`${reasonCd}_${reason}`}>{`${reasonCd}_${reason}`}</MenuItem>
                  ))
                  }
                </Select>
              </div>
            </Grid>
          </Grid>
          {rejectSubReasons && rejectSubReasons.length > 0 ? (
            <Grid container spacing={2} style={{ overflow: 'hidden' }}>
              <Grid item styleName="reason-label" xs={3}>
                <div>
                Sub-Reason
                </div>
              </Grid>
              <Grid item style={{ overflow: 'hidden' }} xs={9}>
                <div onKeyDown={handleKeyDown}>
                  <Select
                    disableUnderline
                    displayEmpty
                    fullWidth
                    input={<OutlinedInput name="selectedRejectedSubReason" />}
                    name="subReason"
                    onChange={handleSubReasonChng}
                    styleName="casereject-reason-dropDown"
                    value={selectedSubRsn}
                  >
                    <MenuItem disabled value="">
              Select
                    </MenuItem>
                    {rejectSubReasons
                  && filterSubReasonsByPrefix().map(({ subReason, subReasonCd }) => (
                    <MenuItem key={subReasonCd} value={`${subReasonCd}_${subReason}`}>{`${subReasonCd}_${subReason}`}</MenuItem>
                  ))
                  }
                  </Select>
                </div>
              </Grid>
            </Grid>
          ) : null}
          <Divider style={{ marginBottom: '10px' }} />
          <div styleName="caserej-button">
            <Button color="primary" disabled={!selectedReason || (!selectedSubRsn && rejectSubReasons.length > 0)} onClick={handleReject} variant="contained">
                  OK
            </Button>
            <Button color="primary" onClick={handleClose} variant="outlined">
                  CANCEL
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
CaseRejectPopUp.defaultProps = {
  handleClose: () => { },
};

CaseRejectPopUp.propTypes = {
  caseId: PropTypes.string.isRequired,
  fetchRejectSubReasons: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  inProgress: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rejectCase: PropTypes.func.isRequired,
  rejectReasons: PropTypes.shape().isRequired,
  rejectSubReasons: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  rejectReasons: selectors.caseRejReasons(state),
  rejectSubReasons: selectors.caseRejSubReasons(state),
  inProgress: selectors.isRejectSubRsnsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetchRejectSubReasons: operations.fetchCaseRejSubReasonsOpn(dispatch),
  rejectCase: operations.rejectCaseOperation(dispatch),
});

const CaseRejectPopUpComp = connect(mapStateToProps, mapDispatchToProps)(CaseRejectPopUp);

export default CaseRejectPopUpComp;
