import moment from 'moment-timezone';

const getDateStrFormat = (val) => {
  if (moment.isMoment(val)) {
    return val.format('YYYY-MM-DD');
  }
  if (!val || val === 'Invalid date') {
    return null;
  }
  return moment(val).format('YYYY-MM-DD');
};

const compareDateValues = (obj1, obj2) => {
  const obj1PaymentDt = getDateStrFormat(obj1.paymentDate);
  const obj2PaymentDt = getDateStrFormat(obj2.paymentDate);
  const obj1HssnDt = getDateStrFormat(obj1.hssnreportedDate);
  const obj2HssnDt = getDateStrFormat(obj2.hssnreportedDate);

  return obj1PaymentDt === obj2PaymentDt && obj1HssnDt === obj2HssnDt;
};

const compareDateValuesForRepay = (obj1, obj2) => {
  const obj1PaymentDt = getDateStrFormat(obj1.paymentDate);
  const obj2PaymentDt = getDateStrFormat(obj2.paymentDate);
  return obj1PaymentDt === obj2PaymentDt;
};
const findItem = (array, id) => array.find(obj => obj.terms === id);

const compareArrays = (arr1 = [], arr2 = [], isRepay = 0) => {
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0) {
    return true;
  }
  if (isRepay === 1) {
    return arr1.some((obj1) => {
      const obj2 = findItem(arr2, obj1.terms);
      return !compareDateValuesForRepay(obj1, obj2);
    });
  }
  return arr1.some((obj1) => {
    const obj2 = findItem(arr2, obj1.terms);
    return !compareDateValues(obj1, obj2);
  });
};

export default compareArrays;
