import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import {
  NON_DELEGATED, NON_DEL_APPROVAL_DATE,
} from 'constants/eval';
import DatePickerStyles from './DatePickerStyles';

const DatePicker = ({
  format, handleChange, id, override, value, isViewOnly, endDate,
  columnName, delegationIndicator,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const classes = DatePickerStyles(override);
  const onDateChange = (date) => {
    setSelectedDate(date);
    handleChange(id, date);
  };
  const dateValue = moment(value).isValid() ? moment(value).format('MM/DD/YYYY') : null;
  if (!override) {
    return (
      <FormControl className={classes.root}>
        {/* <InputLabel className={classes.labelName} htmlFor={label}>{label}</InputLabel> */}
        <TextField
          className={classes.TextFieldDiv}
          disabled
          id={id}
          InputProps={{
            disableUnderline: true,
            style: { paddingLeft: '5px' },
          }}
          value={(columnName !== NON_DEL_APPROVAL_DATE
            || delegationIndicator === NON_DELEGATED) ? dateValue : ''}
        />
      </FormControl>
    );
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        className={classes.DatePickerRoot}
        disabled={isViewOnly}
        format={format}
        id={id}
        InputProps={{
          disableUnderline: true,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        margin="normal"
        maxDate={endDate}
        onChange={onDateChange}
        value={selectedDate}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  columnName: PropTypes.string,
  delegationIndicator: PropTypes.string,
  endDate: PropTypes.string,
  format: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool,
  override: PropTypes.bool,
  value: PropTypes.string,
};

DatePicker.defaultProps = {
  endDate: undefined,
  format: 'DD MMMM YYYY',
  handleChange: () => {},
  columnName: '',
  delegationIndicator: '',
  override: false,
  value: '22/1/2023',
  isViewOnly: false,
};

export default DatePicker;
