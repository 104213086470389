import { makeStyles } from '@material-ui/core/styles';

const DatePickerStyles = makeStyles(override => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  labelName: {
    paddingTop: '8px',
    transform: 'none',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginLeft: '20px',
    color: '#4E586E',
    display: 'flex',
  },
  TextFieldDiv: {
    // marginLeft: '335px',
    '& .MuiInputBase-root': {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#4E586E',
      border: hookData => (hookData.override && hookData.showDetails ? 'none' : '1px solid #E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
  },
  DatePickerRoot: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0em',
    border: '1px solid #E0E4EF',
    borderRadius: '4px',
    marginTop: '3px',
    paddingLeft: '8px',
    backgroundColor: override ? 'white' : 'default',
    '& .MuiInputBase-root': {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#4E586E',
      border: 'none',
      borderRadius: '0px',
    },
    '& .MuiIconButton-root': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
}));

export default DatePickerStyles;
