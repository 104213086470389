import {
  SUBMIT_TO_FNMA, FETCH_SMDU_HISTORY,
  FETCH_TRANSACTION_DATA,
  FETCH_SMDU_PRIOR_WORKOUT_DROPDOWN,
  SUBMIT_TO_REPORT_INVESTOR,
  SET_TO_REPORT_INVESTOR,
  SAVE_PRIOR_WORKOUT,
  CLOSE_POP_UP,
  FETCH_REPORTING_DATA,
  SET_SMDU_REPORTING_UPDATES,
  GET_RESL_TYPE,
} from './types';


const submitToFNMA = payload => ({
  type: SUBMIT_TO_FNMA,
  payload,
});

const fetchSMDUHistory = () => ({
  type: FETCH_SMDU_HISTORY,
});

const transactionData = () => ({
  type: FETCH_TRANSACTION_DATA,
});

const fetchSMDUPriorWorkoutData = () => ({
  type: FETCH_SMDU_PRIOR_WORKOUT_DROPDOWN,
});

const submitToReportInvestor = payload => ({
  type: SUBMIT_TO_REPORT_INVESTOR,
  payload,
});

const updateReportInvestorRes = payload => ({
  type: SET_TO_REPORT_INVESTOR,
  payload,
});

const savePriorWorkoutToDB = payload => ({
  type: SAVE_PRIOR_WORKOUT,
  payload,
});

const closePopUp = () => ({
  type: CLOSE_POP_UP,
});

const getReportingDataAction = () => ({
  type: FETCH_REPORTING_DATA,
});

const getReportingUpdatesAction = () => ({
  type: SET_SMDU_REPORTING_UPDATES,
});

const fetchResolutionTypeAction = evalId => ({
  type: GET_RESL_TYPE,
  payload: evalId,
});

export {
  submitToFNMA,
  fetchSMDUHistory,
  transactionData,
  fetchSMDUPriorWorkoutData,
  submitToReportInvestor,
  updateReportInvestorRes,
  savePriorWorkoutToDB,
  closePopUp,
  getReportingDataAction,
  getReportingUpdatesAction,
  fetchResolutionTypeAction,
};
