import {
  Button, MenuItem, Select, FormControlLabel, Checkbox, Radio, RadioGroup,
} from '@material-ui/core';
import SubmittedRecords from 'components/SubmittedRecords';
import { caseOptions, varifyName, varifyOption } from 'constants/SupportAdmin';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import SweetAlertBox from '../../../components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../state/ducks/dashboard';
import Popup from '../../../components/Popup';
import './ForceCreateEvalCase.css';

const ForceCreateEvalCase = ({
  setIdType, recordsInfo, submitForceCreateEvalCaseOperation,
  resultOperation, closeSweetAlert,
}) => {
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    loanId: '',
    requestorID: '',
    evalType: '',
    preApprovedType: '',
    comments: '',
    evaluationRules: false,
    caseRules: false,
  });
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [disableCreate, setDisableCreate] = useState(true);
  const [ispopupVisible, setIspopupVisible] = useState(false);
  const [renderContent, setRenderContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  React.useEffect(() => {
    setIdType('loan');
  }, []);

  useEffect(() => {
    if (resultOperation && resultOperation.status) {
      const { status, level } = resultOperation;
      setRenderContent(status);
      setMsgType(level || 'Failed');
      setIsOpen(true);
    }
  }, [resultOperation]);

  useEffect(() => {
    if (isSubmitted && recordsInfo.successCount !== 0 && recordsInfo.failureCount !== 0) {
      setCaseObj(
        {
          ticket: '',
          loanId: '',
          requestorID: '',
          evalType: '',
          preApprovedType: '',
          comments: '',
          evaluationRules: false,
          caseRules: false,
        },
      );
      setIsSubmitted(false);
      setDisableButton(true);
      setDisableCreate(true);
    }
  }, [recordsInfo]);


  const handleDialogClose = () => {
    setIsOpen(false);
    closeSweetAlert();
  };

  const handleOnBlur = () => {
    const {
      ticket,
      loanId,
      requestorID,
      evalType,
      comments,
    } = caseObj;
    // Disaster Case/ Standard Eval/ Pandemic Eva - skip rejection -disable
    let disablebtn = true;
    if (ticket !== '' || loanId !== '' || requestorID !== '' || comments !== '' || evalType !== '') {
      disablebtn = false;
    }
    setDisableButton(disablebtn);
    let disableCrt = true;
    if (ticket !== '' && loanId !== '' && requestorID !== '' && comments !== '' && evalType !== '' && varifyOption.includes(evalType)) {
      disableCrt = false;
    }
    setDisableCreate(disableCrt);
  };

  const {
    evalType, preApprovedType, caseRules, evaluationRules,
  } = caseObj;

  React.useEffect(() => {
    handleOnBlur();
  }, [evalType, preApprovedType, evaluationRules, caseRules]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = !varifyName.includes(name) ? value : checked;
    if (name === 'evalType') {
      updatedObject.evaluationRules = false;
      updatedObject.caseRules = false;
      updatedObject.preApprovedType = '';
      setIsCheckboxVisible(['pre-approved-eval', 'state-eval'].includes(value));
    }
    setCaseObj(updatedObject);
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      loanId: '',
      requestorID: '',
      evalType: '',
      preApprovedType: '',
      comments: '',
      evaluationRules: false,
      caseRules: false,
    });
    setDisableButton(true);
    setDisableCreate(true);
  };

  const handleSubmit = () => {
    submitForceCreateEvalCaseOperation(caseObj);
    setIspopupVisible(false);
    setIsSubmitted(true);
  };

  // eslint-disable-next-line max-len
  const renderCaseOptions = options => options.map((option) => {
    const value = option.replace('/', '').split(' ').join('-').toLowerCase();
    return <MenuItem value={value}>{option}</MenuItem>;
  });

  return (
    <div styleName="form-fields-container">
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="ServiceNow Ticket" name="ticket" type="number" value={caseObj.ticket} />
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Loan ID" name="loanId" type="number" value={caseObj.loanId} />
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Requestor ID" name="requestorID" value={caseObj.requestorID} />
      <div styleName="flex-container select-field-container">
        <p>
          Eval Type
        </p>
        <Select
          displayEmpty
          name="evalType"
          onChange={handleChange}
          value={caseObj.evalType}
        >
          <MenuItem value="">Select</MenuItem>
          {renderCaseOptions(caseOptions)}
        </Select>
      </div>
      {caseObj.evalType === 'pre-approved-eval' && (
        <div styleName="flex-container radio-container">
          <p>
            Pre-Approved Type
          </p>
          <RadioGroup
            name="preApprovedType"
            onChange={handleChange}
            row
            value={caseObj.preApprovedType}
          >
            <FormControlLabel
              control={<Radio size="small" />}
              label="Inflight"
              value="inflight"
            />
            <FormControlLabel
              control={<Radio size="small" />}
              label="Non Delegated"
              value="nonDelegated"
            />
          </RadioGroup>
        </div>
      )}
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Comments" multiline name="comments" value={caseObj.comments} />
      <div styleName="flex-container radio-container">
        <p>
          Skip Rejection Rules
        </p>
        <div style={{ width: '11rem' }}>
          <FormControlLabel control={<Checkbox checked={caseObj.evaluationRules} disabled={!isCheckboxVisible} name="evaluationRules" onChange={handleChange} />} label="Evaluation Rules" style={{ minWidth: '125px', marginBottom: '6px' }} />
          <FormControlLabel control={<Checkbox checked={caseObj.caseRules} disabled={!isCheckboxVisible} name="caseRules" onChange={handleChange} />} label="Case Rules" style={{ minWidth: '125px', marginBottom: '6px' }} />
        </div>
      </div>
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableCreate} onClick={() => setIspopupVisible(true)} variant="contained">
          CREATE
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Loan ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
      {resultOperation && resultOperation.status && (
      <SweetAlertBox
        message={renderContent}
        onConfirm={() => handleDialogClose()}
        show={isOpen}
        type={msgType}
      />
      )}
    </div>
  );
};

ForceCreateEvalCase.defaultProps = {
  closeSweetAlert: () => {},
};

ForceCreateEvalCase.propTypes = {
  closeSweetAlert: PropTypes.func,
  recordsInfo: PropTypes.shape().isRequired,
  resultOperation: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  setIdType: PropTypes.func.isRequired,
  submitForceCreateEvalCaseOperation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  closeSweetAlert: dashboardOperations.closeSweetAlert(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForceCreateEvalCase);
