import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { PropTypes } from 'prop-types';
import '../BoardingTemplatePage.css';
import * as R from 'ramda';

const PreApprovedToggleSwitch = ({
  preApprovedStatus, setPreApprovedStatus, inFlightStatus, setIsSubmitDisabled,
  loanIds, selectedCaseType, selectedPhase,
}) => {
  const [isPreapprovedDisabled, setPreapprovedDisabled] = React.useState(false);

  const handleToggle = () => {
    if (!isPreapprovedDisabled) {
      setPreApprovedStatus(!preApprovedStatus);
    }
  };

  useEffect(() => {
    const re = /[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/;
    if (loanIds === '' || !re.test(loanIds)) {
      setIsSubmitDisabled(!(loanIds.trim()
&& !R.isEmpty(selectedPhase) && !R.isEmpty(selectedCaseType)
&& (inFlightStatus || preApprovedStatus)
      ));
    }

    // Define valid case types and their corresponding boarding phases
    const validCaseTypePhasePairs = [
      { caseType: 'Disaster FHA Modification', phase: 'Sent for Approval' },
      { caseType: 'Flex Modification Trial', phase: 'Active Trial' },
      { caseType: 'Investor Mod', phase: 'Sent for Approval' },
      { caseType: 'Payment Deferral', phase: 'Sent for Approval' },
      { caseType: 'Payment Deferral Disaster', phase: 'Sent for Approval' },
      { caseType: 'Streamline Modification', phase: 'Sent for Approval' },
      { caseType: 'Streamline Modification Trial', phase: 'Active Trial' },
      { caseType: 'VA Streamline Modification', phase: 'Sent for Approval' },
      { caseType: 'VA Streamline Modification Trial', phase: 'Active Trial' },
    ];

    // Update the disabled state for the toggle
    const isDisabled = validCaseTypePhasePairs.some(
      pair => pair.caseType === selectedCaseType && pair.phase === selectedPhase,
    );
    setPreapprovedDisabled(!isDisabled);
  }, [loanIds, selectedCaseType, selectedPhase, inFlightStatus, preApprovedStatus]);
  return (
    <>
      <div styleName="loan-numbers">
        <span styleName="typography">
          {'Pre Approved'}
        </span>
      </div>
      <Grid alignItems="center" component="label" container>
        <Grid item>
          <Switch
            checked={preApprovedStatus}
            color="primary"
            disabled={isPreapprovedDisabled}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name="toggle"
            onChange={handleToggle}
          />
        </Grid>
      </Grid>
    </>
  );
};

PreApprovedToggleSwitch.propTypes = {
  inFlightStatus: PropTypes.bool.isRequired,
  loanIds: PropTypes.string.isRequired,
  preApprovedStatus: PropTypes.bool.isRequired,
  selectedCaseType: PropTypes.string.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired,
  setPreApprovedStatus: PropTypes.func.isRequired,

};

export default PreApprovedToggleSwitch;
