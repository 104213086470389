import {
  FETCH_HISTORY_DATA,
  BOARDING_TEMPLATE_SUBMIT,
  ONBOARD_TEMPLATE_DATA,
  PROCESS_BOARDING_TEMPLATE,
  INITIATE_IMPORT_PROCESS,
  SET_CASE_TYPE_OPTIONS,
  SET_BOARDING_PHASES,
  DOWNLOAD_FILE,
  SET_SUBMIT_BTN,
  CLEAR_DATA,
} from './types';


const fetchBoardingTemplateHistoryAction = (pageNumber, pageSize, filter, order) => ({
  type: FETCH_HISTORY_DATA,
  payload: {
    pageNumber, pageSize, filter, order,
  },
});

const submitBoardingTemplateAction = () => ({
  type: BOARDING_TEMPLATE_SUBMIT,
});

const submitOnBoardTemplateAction = data => ({
  type: ONBOARD_TEMPLATE_DATA,
  payload: data,
});

const processBoardingTemplateAction = (onboardTemplate, file) => ({
  type: PROCESS_BOARDING_TEMPLATE,
  payload: {
    onboardTemplate,
    file,
  },
});

const initiateImportProcessAction = validRecords => ({
  type: INITIATE_IMPORT_PROCESS,
  payload: validRecords,
});

const setCaseTypesAction = () => ({
  type: SET_CASE_TYPE_OPTIONS,
});

const setBoardingPhasesAction = data => ({
  type: SET_BOARDING_PHASES,
  payload: data,
});

const downloadFileAction = payload => ({
  type: DOWNLOAD_FILE,
  payload,
});

const setSubmitBtnAction = payload => ({
  type: SET_SUBMIT_BTN,
  payload,
});

const clearDataAction = () => ({
  type: CLEAR_DATA,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchBoardingTemplateHistoryAction,
  submitBoardingTemplateAction,
  submitOnBoardTemplateAction,
  processBoardingTemplateAction,
  initiateImportProcessAction,
  setCaseTypesAction,
  setBoardingPhasesAction,
  downloadFileAction,
  setSubmitBtnAction,
  clearDataAction,
};
